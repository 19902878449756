import Vue from "vue";
import Router from "vue-router";
import routes from "@/router/routes.js";

Vue.use(Router);

let router = new Router({
  routes,
  mode: "history",
  base: __dirname,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

export default router;