function load(component, folder) {
    // '@' is aliased to src/components
    if (folder) {
      return () =>
        import(`@/pages/${folder}/${component}.vue`);
    }
    return () =>
      import(`@/pages/${component}.vue`);
  }

export default [{
        path: "/",
        name: "hjem",
        component: load('Home')
    },
    {
        path: "/Om",
        name: "om",
        component: load('About')
    }
]