<template>
   <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-brand href="/" class="ml-3">Flaggtider.no</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-links" is-nav>
      <b-navbar-nav class="ml-auto text-white">
        <b-nav-item href="/#finn" :active='$route.name =="hjem"'>Finn din by</b-nav-item> 
        <b-nav-item href="/#oversikt">Flaggtider i Norge</b-nav-item> 
        <b-nav-item href="/Om" :active='$route.name =="om"'>Om</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "NavBar"
}
</script>

<style>

</style>