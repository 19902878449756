<template>
   <footer class="text-center">
      <hr>
      <p>Laget av <a href="https://risa.consulting" target="_blank">Risa IT Consulting</a> @ {{ new Date().getFullYear() }}</p>
    </footer>
</template>

<script>
export default {
  name: "footer"
}
</script>

<style>

</style>